import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

import ReactMarkdown from "react-markdown";
import 'bootstrap/dist/css/bootstrap.min.css';

const PagePreview = () => {
    const { pageName } = useParams();
    const location = useLocation();
    const [content, setContent] = useState('');

    useEffect(() => {
        if (location.state && location.state.content) {
            setContent(location.state.content);
        } else {
            // Fallback in case content was not passed through location.state
            const fetchPageContent = async () => {
                if (pageName) {
                    try {
                        const response = await axios.get(process.env.REACT_APP_API_URL+'/api/FileProcessing/pages/'+pageName);
                        setContent(response.data);
                    } catch (error) {
                        console.error('Error fetching page content:', error);
                    }
                }
            };

            fetchPageContent();
        }
    }, [pageName, location.state]);

    return (
        <div className="container mt-5">
            <div>
                <ReactMarkdown>
                    {content}
                </ReactMarkdown>
            </div>
        </div>
    );
};

export default PagePreview;
