import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Monthly.css';
import Bank from './Banks'; 


export default function DonateMonthly() {



  return (
   <>
   <div className="container mt-5">
          <h1>Word pilaar van de moskee </h1>
          <br/>
          <p>
          Moskee Arrahman is een vereniging die zich volledig drijft op de inzet van haar vrijwilligers en haar gulle donateurs. Wij willen jouw vragen om ons te helpen. Wordt lid door de stappen uit te voeren zoals die zijn beschreven in de onderstaande links. Selecteer uw bank:
          </p>
          <Bank/>
   </div>
   </>
  );
}