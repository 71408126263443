import React from 'react';
import HomeText from '../components/Navmenu/HomeText';

function Home() {
    return (
        <div>
            <HomeText />
        </div>
    );
}

export default Home;
