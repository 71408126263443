import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navmenu.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import Logo from '../../assets/logo Moskee Arrahman.jpeg';
import AuthorizeView from '../Authentication/AuthorizeView';

function NavMenu() {
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <nav className="navbar">
                <div className="nav-container">
                    <div className="nav-logo">
                        <a href="/"><img src={Logo} className="nav-logo-img" alt="Moskee Arrahman" /></a>
                    </div>
                    <ul className={collapsed ? "nav-menu" : "nav-menu active"}>
                        <li className="nav-item">
                            <NavLink
                                to="/onderwijs"
                                activeClassName="active"
                                className="nav-links"
                                onClick={toggleNavbar}
                            >
                                Onderwijs
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/bekeren"
                                activeClassName="active"
                                className="nav-links"
                                onClick={toggleNavbar}
                            >
                                Bekeerlingen
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/vrijdagpreken"
                                activeClassName="active"
                                className="nav-links"
                                onClick={toggleNavbar}
                            >
                                Vrijdagpreek
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/donatie"
                                activeClassName="active"
                                className="nav-links"
                                onClick={toggleNavbar}
                            >
                                Steun ons
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/contact"
                                activeClassName="active"
                                className="nav-links"
                                onClick={toggleNavbar}
                            >
                                Contact
                            </NavLink>
                        </li>
                        <AuthorizeView>
                            <li className="nav-item">
                                <NavLink
                                    to="/logout"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={toggleNavbar}
                                >
                                    Log out
                                </NavLink>
                            </li>
                        </AuthorizeView>
                    </ul>
                    <div className="nav-icon" onClick={toggleNavbar}>
                        {collapsed ? (
                            <span className="icones">
                                <HamburgetMenuOpen />
                            </span>
                        ) : (
                            <span className="icones">
                                <HamburgetMenuClose />
                            </span>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavMenu;
