import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import favicon from '../../assets/gebedstijden.png';
import DonateFoto from '../../assets/donate.jpg';
import Logo from '../../assets/vrijdagpreek.jpg';
import './HomeText.css';

function HomeText() {
    return (
        <>
        <div className="container mt-5">
            <header className="text-center mb-4">
                <h1 className="display-4"> Moskee Arrahman</h1>
            </header>
            <section className="row text-center">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card shadow-sm uniform-card">
                        <img src={Logo} className="card-img-top" alt="Vrijdagpreek" />
                        <div className="card-body">
                            <h5 className="card-title">Vrijdagpreken</h5>
                            <p className="card-text">Lees hier de nederlandse vertaling van de vrijdagpreek van de moskee</p>
                            <a href="/vrijdagpreken" className="btn btn-primary rounded-pill mb-2">Bekijk vrijdagpreek</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card shadow-sm uniform-card">
                        <img src={DonateFoto} className="card-img-top" alt="Doneren" />
                        <div className="card-body">
                            <h5 className="card-title">Doneren aan de moskee</h5>
                            <p className="card-text">Maandelijkse en eenmalige donaties zijn mogelijk</p>
                            <a href="/donatie" className="btn btn-primary rounded-pill mb-2">Klik hier om te doneren</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card shadow-sm uniform-card">
                        <img src={favicon} className="card-img-top" alt="Gebedstijden" />
                        <div className="card-body">
                            <h5 className="card-title">Gebedstijden</h5>
                            <p className="card-text">Locatie noordwijk</p>
                            <a href="/gebedstijden" className="btn btn-primary rounded-pill mb-2">Bekijk gebedstijden</a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        </>
    );
}

export default HomeText;